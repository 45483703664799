import axios from 'axios'
import { getUserState } from '@/firebase'
import { gql } from 'graphql-tag'
import store from '@/store'
import router from '@/router'

const getAuthToken = async () => {
	const userState = await getUserState()
	return 'Bearer ' + userState.accessToken
}

const emitirDocument = async (document, id, nameDocument) => {
	let errors = null

	const aquisicaoEscritura =
		store.getters['contracts/getSelectedContract'].aquisicao_escritura

	if (document == 1 && aquisicaoEscritura == 'Não') {
		errors = await emitirIR(id)
	} else if (document == 2 && aquisicaoEscritura == 'Não') {
		errors = await emitirExtrato()
	} else if (document == 3 && aquisicaoEscritura == 'Não') {
		errors = await emitirAnuencia()
	} else if (document == 4 && aquisicaoEscritura == 'Não') {
		errors = await emitirAutorizacaoEscritura()
	} else if (document == 5 && aquisicaoEscritura == 'Não') {
		errors = await emitirQuitacaoContrato()
	} else if (document >= 6) {
		errors = await emitirFluigDocument(id, nameDocument)
	}

	if (document < 6 && aquisicaoEscritura == 'Sim') {
		errors = 'Não foi possível emitir documento!'
	}

	if (errors) {
		return errors
	}
}

const emitirIR = async date => {
	let errors = null

	const codCfo = store.state.customer.loggedCustomer.codcfo
	const codcolcfo = store.state.customer.loggedCustomer.codcolcfo

	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/informe-imposto-renda',
			{
				codcolcfo: codcolcfo,
				codcfo: codCfo,
				ano: date,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, response.data.nome)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const emitirQuitacaoContrato = async () => {
	let errors = null

	const codcoligada = store.getters['contracts/getSelectedContract'].codcoligada
	const numvenda = store.getters['contracts/getSelectedContract'].num_venda
	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/termo-quitacao',
			{
				codcoligada: codcoligada,
				numvenda: numvenda,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, response.data.nome)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const emitirAutorizacaoEscritura = async () => {
	let errors = null

	const codcoligada = store.getters['contracts/getSelectedContract'].codcoligada
	const numvenda = store.getters['contracts/getSelectedContract'].num_venda
	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/autorizacao-escritura',
			{
				codcoligada: codcoligada,
				numvenda: numvenda,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, response.data.nome)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const emitirExtrato = async () => {
	let errors = null
	const contrato = store.getters['contracts/getSelectedContract'].contrato

	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/extrato-financeiro',
			{
				contrato: contrato,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, response.data.nome)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const emitirAnuencia = async () => {
	let errors = null

	const codcoligada = store.getters['contracts/getSelectedContract'].codcoligada
	const numVenda = store.getters['contracts/getSelectedContract'].num_venda

	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/termo-anuencia',
			{
				codcoligada: codcoligada,
				numvenda: numVenda,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, response.data.nome)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const emitirFluigDocument = async (documentId, nameDocument) => {
	let errors = null
	await axios
		.post(
			'https://api.saobento.com.br/cliente/documentos/documento-fluig',
			{
				documentId: documentId,
			},
			{ headers: { Authorization: await getAuthToken() } }
		)
		.then(response => {
			showPdf(response.data.base64, nameDocument)
		})
		.catch(function(error) {
			if (error.response.data.message) {
				errors = error.response.data.message
			} else if (error.response.data.error) {
				errors = error.response.data.error
			} else if (error.request) {
				errors = error.request
			} else {
				errors = error.message
			}
		})

	if (errors) {
		return errors
	}
}

const listContractDocuments = async apolloClient => {
	let errors = null
	let documento = null
	const codcoligada = store.getters['contracts/getSelectedContract'].codcoligada
	const numvenda = store.getters['contracts/getSelectedContract'].num_venda

	await apolloClient
		.query({
			query: gql`
				query getContractDocuments($codcoligada: Int, $numvenda: Int) {
					contract_documents(
						order_by: { ordem_documento: asc }
						where: {
							_and: { codcoligada: { _eq: $codcoligada }, numvenda: { _eq: $numvenda } }
						}
					) {
						id_documento
						nome_documento
						tipo_documento
					}
				}
			`,
			variables: {
				codcoligada: codcoligada,
				numvenda: numvenda,
			},
			fetchPolicy: 'no-cache',
		})
		.then(documents => {
			documento = documents.data.contract_documents
		})
		.catch(error => {
			errors = error
		})

	if (errors) {
		return null
	} else {
		return documento
	}
}

const showPdf = async (pdf, documentName) => {
	router.push({
		name: 'document-viewer',
		params: {
			data: 'data:application/pdf;base64,' + pdf + '',
			documentName: documentName,
		},
	})
}

export default () => ({
	emitirDocument,
	emitirIR,
	emitirExtrato,
	emitirAnuencia,
	listContractDocuments,
	emitirFluigDocument,
})
