import { gql } from 'graphql-tag'
import store from '@/store'

export const updateCustomer = async (customer, apolloClient) => {
	let errors = null
	const uuidUser = store.state.customer.loggedCustomer.uuid

	await apolloClient
		.mutate({
			mutation: gql`
				mutation($uuid: uuid!, $first_name: String!, $name: String!) {
					update_customer_by_pk(
						pk_columns: { uuid: $uuid }
						_set: { first_name: $first_name, name: $name }
					) {
						uuid
					}
				}
			`,
			variables: {
				uuid: uuidUser,
				first_name: customer.first_name,
				name: customer.name,
			},
		})
		.then(() => {
			errors = null
		})
		.catch(error => {
			errors = error.message
		})

	return { errors }
}

export default () => ({
	updateCustomer,
})
