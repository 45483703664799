import AuthService from './auth'
import CustomerService from './customer'
import ContractService from './contract'
import DocumentService from './documents'
import ProcessService from "./process"

export default {
    auth: AuthService(),
    customer: CustomerService(),
    contract: ContractService(),
    document: DocumentService(),
    process: ProcessService(),
}